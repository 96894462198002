import React from "react";
import "./About.css";
import pic from "../../images/ab.jpeg";
// import Award from '../../images/award.png'

export default function About() {
  return (
    <div className="about">
      <div className="a-left">
        <div className="a-card bg"> </div>
        <div className="a-card">
          <img src={pic} alt="" className="a-img" />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">About me</h1>
        <h3 className="a-sub">
          I'm a software engineer specializing in building cross platform mobile
          application using React Native.
        </h3>
        <p className="a-desc">
          I have completed Bachelor's of Engineering in Computer Science in
          2019. Since last one year I have been working on JavaScript,
          TypeScript, React Native, React.js, Next.js, Redux-toolkit,
          Redux-thunk, Redux-saga and CSS.
        </p>
        {/* <div className="a-award
            <img src={Award} alt="" className="a-award-img" />
        </div> */}
      </div>
    </div>
  );
}
