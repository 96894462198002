import React, { useContext, useRef, useState } from "react";
import "./contact.css";
import phone from "../../images/phone.png";
import email from "../../images/email.png";
import address from "../../images/address.png";
import emailjs from "@emailjs/browser";
import { ThemeContext } from "../../context";

export default function Contact() {
  const formRef = useRef();
  const [done, setDone]=useState(false);
  const theme =useContext(ThemeContext);
  const darkmode =theme.state.darkmode;
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_31edo5d",
        "template_mjrc619",
        formRef.current,
        "FLlucRwqCY6RgwDI3"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true)
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="contact">
      <div className="c-bg"></div>
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-title">Let's discuss your project</h1>
          <div className="c-info">
            <div className="c-info-item">
              <img src={phone} alt="" className="c-icon" />
              +1 513-804-2783
            </div>
            <div className="c-info-item">
              <img src={email} alt="" className="c-icon" />
              saurabhkumar679@gmail.com
            </div>
            <div className="c-info-item">
              <img src={address} alt="" className="c-icon" />
              Bihar, India
            </div>
          </div>
        </div>
        <div className="c-right">
          <div className="c-desc">
            <p>
              Liked my work ?
              <br />
              <b style={{fontSize:'30px'}}>
              Get in touch.
                </b>
              <br />
             
                My inbox is always open. Whether you have a question or just want to say hi, I will try my best to get back to you.
            
            </p>
          </div>
          <form ref={formRef} onSubmit={handleSubmit} className='contactForm'>
            <input type="text" placeholder="Name" name="user_name" style={{backgroundColor: darkmode ? '#333' :'white'}}/>
            <input type="text" placeholder="Subject" name="user_subject" style={{backgroundColor: darkmode ? '#333' :'white'}}/>
            <input type="text" placeholder="email" name="user_email" style={{backgroundColor: darkmode ? '#333' :'white'}}/>
            <textarea rows="5" placeholder="message" name="message" style={{backgroundColor: darkmode ? '#333' :'white'}}/>
            <button>Submit</button>
            <br />
            { done && 'Your message sent successfully.  Thank you...'}
          </form>
          
        </div>
      </div>
    </div>
  );
}
