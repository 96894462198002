import React from 'react';
import './product.css';

export default function Product({prodPic,link}) {

  const newTab=()=>{
    window.open(link,'_blank')
  }
  return (
    <div  className='product' onClick={newTab}>
      {/* <a href={link}> */}
        <div className="p-browser">
            <div className="p-circle"></div>
            <div className="p-circle"></div>
            <div className="p-circle"></div>
        </div>
        <img src={prodPic} alt='product'  className='prodImg'/>
        {/* </a> */}
    </div>
  )
}
