import About from "./component/about/About";
import Intro from "./component/Intro/Intro";
import ProductList from "./component/ProductList/ProductList";
import Contact from "./component/contact/Contact";
import Toggle from "./component/toggle/Toggle";
import { useContext } from "react";
import { ThemeContext } from "./context";

function App() {
  const theme =useContext(ThemeContext);
  const darkmode =theme.state.darkmode;

  return (
    <div style={{backgroundColor: darkmode ? '#222' :'white' ,color:darkmode ?'white' :'black'}}>  
    <Intro />
    <About />
    <ProductList />
    <Contact />
    <Toggle />
    </div>
  );
}

export default App;
