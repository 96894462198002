import React, { useContext } from 'react';
import './Toggle.css';
import sun from '../../images/sun.png';
import moon from '../../images/moon.png';
import { ThemeContext } from '../../context';


export default function Toggle() {
    const theme=useContext(ThemeContext);
    const dispatch=theme.dispatch;

    const handleClick=()=>{
        dispatch({type:'TOGGLE'})
    }
  return (
    <div className='toggle' onClick={handleClick}>
        <img src={sun} alt="sun" className='t-icon'/>
        <img src={moon} alt="moon" className='t-icon'/>
        <div className="t-button" style={{left:theme.state.darkmode ?'0':'25px'}}></div>
    </div>
  )
}
