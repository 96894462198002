import React from "react";
import "./productList.css";
import Product from "../product/Product";
import fitCLub from "./../../images/fitClub.png";
import realEstate from "./../../images/realEstate.png";
import easyBank from "./../../images/easyBank.png";
import uberEatsClone from "./../../images/uberEatsClone.png";
import nextBlog from "./../../images/nextBlog.png";
import chatApp from "./../../images/chatApp.png";

export default function ProductList() {
  return (
    <div className="productList">
      <div className="pl-text">
        <h1 className="pl-title">Something I've Built</h1>
        <p className="pl-desc">
          Here are some projects that I have built. Most of Web apps are built using
          React.js, Next.js and tailwind css. Mobile apps are built using React Native,
          redux-thunk used for state management and async-storage used for
          storing data.
        </p>
        <p className="pl-desc"></p>
        <p className="pl-desc">
          Currently building Mobile Chat App using
          React Native, Node.js, Express.js and MongoDB.
        </p>
        <p className="pl-desc">
         Click on project image, It will land you to my project source code or deployed project.
        </p>
      </div>
      <div className="pl-list">
        <Product  
          prodPic={chatApp}
          link="https://github.com/saurabhkara/Chat-App-React-Native"
        />
        <Product
          prodPic={nextBlog}
          link="https://github.com/saurabhkara/Nextjs-Blog"
        />
        <Product prodPic={fitCLub} link="https://fitclubproject.pages.dev" />
        <Product
          prodPic={uberEatsClone}
          link="https://github.com/saurabhkara/uberEatsClone"
        />
        <Product prodPic={realEstate} link="https://realestate-3qa.pages.dev" />
        <Product prodPic={easyBank} link="https://easybank-2o4.pages.dev/" />
      </div>
    </div>
  );
}
